import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TenantsRestService} from "../../../services/apis/tenants-rest.service";
import {AuthorizationService} from "../../../../common/services/authorization.service";
import {HttpConnectivityComponent} from "../../../components/http-connectivity/http-connectivity.component";

@Component({
    selector: 'ac-device-general-tab',
    templateUrl: './ac-device-general-tab.component.html'
})
export class AcDeviceGeneralTabComponent {

    @Input() audioCodesDevice: any;
    @Input() additionalSettings: any;
    @Input() isMultiple: boolean;
    @Input() isServiceAttached: boolean;
    @Input() isEdit: boolean;
    @Input() dialogConfig: any;
    @Input() softwareFileList: any;
    @Input() initializeFirmwareVersionAndSupportingProducts: Function;
    @Input() initializeOptionListArrays: Function;
    @Input() updatePreProvInfoProperties: Function;
    @Input() selectedTenantFullObject: any;
    @Input() httpElement: HttpConnectivityComponent;
    @Output() firmwareFileOptionsChanged = new EventEmitter<any>();
    @Output() configurationFileOptionsChanged = new EventEmitter<any>();
    @Input() services: any;

    FIRMWARE_FILES = ['CMP_TYPE', 'RMT_TYPE', 'RMS_TYPE'];
    CONF_FILES = ['CLI_SCRIPT_TYPE', 'CONF_TYPE', 'INI_STAND_ALONE_TYPE'];

    configuredByItems = [
        {text: 'IP Address', value: 'ipAddress'},
        {text: 'FQDN', value: 'deviceFqdn'},
        {text: 'Serial Number', value: 'serialNum'},
    ];

    configuredByMultipleItems = [
        {text: 'IP Address Range', value: 'ipAddressRange'},
        {text: 'IP Address List', value: 'ipAddressList'},
        {text: 'Serial Number List', value: 'serialNumberList'},
    ];
    isSystemAdminOrOperatorLevel;

    constructor(private authorizationService: AuthorizationService,
                private tenantsRestService: TenantsRestService) {
        this.isSystemAdminOrOperatorLevel = this.authorizationService.isSystemAdminOrOperatorLevel();
    }

    tenantChanged = (tenantId) => {
        const selectedTenant = this.tenantsRestService.getEntityById(tenantId);
        this.handleTenantChange();
        this.updateFilesAccordingToTenantChange(selectedTenant);
        this.initializeFirmwareVersionAndSupportingProducts();
        this.initializeOptionListArrays(this.dialogConfig.dialogData);
    };

    configuredByChanged = (value) => {
        this.additionalSettings.configuredBy = value;

        if(value === 'deviceFqdn'){
            this.audioCodesDevice.fqdnEnabled = value === 'deviceFqdn';
        }

        (value === 'deviceFqdn' || value === 'serialNum') && delete this.audioCodesDevice.ipAddress;

        if (value === 'deviceFqdn' || value === 'ipAddress') {
            delete this.audioCodesDevice.sbcInfo.serialNum;
            delete this.audioCodesDevice.sbcInfo.secondSerialNum;
        }

        (value === 'ipAddress' || value === 'serialNum') && delete this.audioCodesDevice.sbcInfo.deviceFqdn;
    };

    handleTenantChange = () => {
        if (this.additionalSettings.tenantId !== -1 && !this.isEdit) {
            this.selectedTenantFullObject = this.tenantsRestService.getEntityById(this.additionalSettings.tenantId);

            setTimeout(() => {// tenantChange fired before httpObject is ready
                if (!this.isEdit && this.selectedTenantFullObject?.snmpProfile && this.selectedTenantFullObject?.httpProfile && this.httpElement?.originalHTTPObject) {
                    this.httpElement.originalHTTPObject.gatewayUser = this.selectedTenantFullObject.httpProfile.gatewayUser;
                    this.httpElement.originalHTTPObject.gatewayPassword = this.selectedTenantFullObject.httpProfile.gatewayPassword;
                    this.httpElement.originalHTTPObject.httpsConnection = this.selectedTenantFullObject.httpProfile.httpsConnection;
                }
            });
        } else {
            this.selectedTenantFullObject = undefined;
        }
    };

    updateFilesAccordingToTenantChange = (selectedTenant) => {
        const firmwareFileOptionsList = this.filterFilesByTypeAndTenant(selectedTenant, this.FIRMWARE_FILES);
        firmwareFileOptionsList.unshift({id: -1 , viewName: ''});
        const configurationFileOptionsList = this.filterFilesByTypeAndTenant(selectedTenant, this.CONF_FILES);

        this.firmwareFileOptionsChanged.emit(firmwareFileOptionsList);
        this.configurationFileOptionsChanged.emit(configurationFileOptionsList);

        this.audioCodesDevice.sbcInfo.preProvInfo && this.updatePreProvInfoProperties();
    };

    filterFilesByTypeAndTenant = (selectedTenant, type) => {
        return this.softwareFileList.filter((file) => {
            const tenantOrSystem = (file.tenantId === -1 || !selectedTenant) ? 'System' : selectedTenant.name;
            file.viewName = file.name + ' (' + tenantOrSystem + ')';
            return (type.indexOf(file.fileType) >= 0) && (file.tenantId === -1 || file.tenantId === selectedTenant.id);
        });
    };

}
