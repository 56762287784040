import {Injectable} from '@angular/core';
import {ConfirmDialogData, DialogConfig, PromiseService} from 'ac-infra';
import {RegionsRestService} from '../apis/regions-rest.service';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GenericDeviceDialogComponent} from '../../dialogs/generic-device-dialog/generic-device-dialog.component';
import {TeamsDeviceDialogComponent} from '../../dialogs/teams-device-dialog/teams-device-dialog.component';
import {LyncDeviceDialogComponent} from '../../dialogs/lync-device-dialog/lync-device-dialog.component';
import {DevicesRestService} from '../apis/devices-rest.service';
import {
    SoftwareManagerRestService
} from '../../../system/configuration/configuration-api/software-manager-rest.service';
import {MessagesService} from '../../../common/utilities/messages.service';
import {AcDeviceDialogComponent} from '../../dialogs/ac-device-dialog/ac-device-dialog.component';
import {
    FilterChangedProperties,
    FilterState
} from '../../../common/components/ac-filter/services/ac-filter-state.service';
import {MetadataService} from '../../../metadata/metadata.service';
import {RestResponseSuccess} from '../../../common/server-actions/rest';
import {MultipleDeviceConverterService} from '../../multiple-device-converter.service';
import {MoveDeviceDialogComponent} from '../../dialogs/move-device-dialog/move-device-dialog.component';
import {Actions} from '../../../common/server-actions/actions';
import {GeoSelectionService} from '../../maps/services/geo-selection.service';
import {ServerInfoService} from "../../../common/services/server-info.service";

export interface deviceFileDetails {
    osVersionList: any[];
    mcTypeList: any[];
    isAllDevicesSWVersionAbove7_3: boolean;
    isAllDevicesInSBCSupportedList: boolean;
    isAllDevicesHA: boolean;
    clusterManagerRadioGroup: any;
}

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class DevicesActionsService extends Actions {

    audioCodesDevicesList;
    deviceEmptyObj = require('../../dialogs/ac-device-dialog/device.empty.json');
    teamsDeviceEmptyObj = require('../../dialogs/teams-device-dialog/teams-device.empty.json');

    visibleFieldsForDeviceInfo = ['sbcInfo.deviceFqdn', 'ipAddress', 'swVersion', 'sbcInfo.osVersion', 'sbcInfo.serialNum',
        'sbcInfo.serialNumString', 'sbcInfo.SBAsInfo.ipAddress', 'sbcInfo.SBAsInfo.sbaVersion', 'lyncInfo.FQDN',
        'lyncInfo.reportedFrontEndName', 'lyncInfo.monitorDbIp', 'lyncInfo.monitorDbPort',
        'lyncInfo.monitorDbInstanceName', 'productType', 'sbcInfo.isHA', 'applicationsStatus.management.mismatchState',
        'pmStatus', 'tenantId', 'regionId', 'serviceId', 'teamsInfo.msTenantId', 'teamsInfo.subscriptionExpiration',
        'applicationsStatus.management.subscriptionStatus',

        'sbcInfo.lastResetTime', 'sbcInfo.additionalInfo.SBCSignalingSessions', 'sbcInfo.additionalInfo.configuredE1Trunks', 'sbcInfo.additionalInfo.SIPRECSessions', 'sbcInfo.additionalInfo.configuredTrunks',
    ];
    typeToComponentMap = {
        AC: {component: AcDeviceDialogComponent, dialogUseFiles: true},
        LYNC: {component: LyncDeviceDialogComponent, dialogUseFiles: true},
        TEAMS: {component: TeamsDeviceDialogComponent},
        GENERIC: {component: GenericDeviceDialogComponent}
    };

    private singleSelectionStatisticsSubject: Subject<any> = new Subject<any>();
    singleSelectionStatistics$ = this.singleSelectionStatisticsSubject.asObservable();

    constructor(public filterState: FilterState,
                public messagesService: MessagesService,
                private geoSelectionService: GeoSelectionService,
                private multipleDeviceConverterService: MultipleDeviceConverterService,
                private softwareManagerRestService: SoftwareManagerRestService,
                private regionsRestService: RegionsRestService,
                private devicesRestService: DevicesRestService) {
        super({entityName: 'device', entityService: devicesRestService, isWsEntity: true});

        this.filterState.filterChanged$.pipe(untilDestroyed(this)).subscribe((filterObj: FilterChangedProperties) => {
            filterObj.type === 'NetworkFilter' && this.geoSelectionService.clearSelection();
        });
    }

    refreshSingleSelectionStatistics = () => {
        this.singleSelectionStatisticsSubject.next(null);
    };

    addDevice = ({deviceDialogType, selectedTopologyItem = undefined, isMultiple = false, isServiceAttached = false, serviceId = undefined}) => {
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        const dialogRef = this.acDialogService.open();
        this.prepareDialogData({deviceDialogType, selectedTopologyItem, isMultiple, isServiceAttached, serviceId, dialogRef});
    };

    editDevice = (tmpDevice) => {
        this.audioCodesDevicesList = MetadataService.getType('AudioCodesDevices');
        const parameters = ServerInfoService.isHybridOperator ? {filter: {isHybrid: true}} : undefined;
        const type = this.audioCodesDevicesList[tmpDevice.productType];
        const familyType: string = type && type.familyType;
        const dialogRef = this.acDialogService.open();
        const success = (response: RestResponseSuccess) => {
            const device = response.data;
            const deviceDialogType = this.typeToComponentMap[familyType] ? familyType : 'AC';
            this.prepareDialogData({deviceDialogType, device, dialogRef, isServiceAttached: device?.serviceId, serviceId: tmpDevice?.serviceId});
        };
        this.devicesRestService.getById({success, parameters, extendParameters: true, skipPopulateFilter: true, id: tmpDevice.id});
    };

    prepareDialogData = ({
        deviceDialogType,
        device = undefined,
        selectedTopologyItem = undefined,
        isMultiple = false,
        isServiceAttached = false,
        serviceId = undefined,
        dialogRef
    }) => {
        if (this.typeToComponentMap[deviceDialogType].dialogUseFiles) {
            this.getDialogOptionListsData(selectedTopologyItem, dialogRef).then((dataObject: any) => {
                this.openDeviceDialog({
                    deviceDialogType,
                    device,
                    dialogComponentType: this.typeToComponentMap[deviceDialogType].component,
                    dataObject,
                    isMultiple,
                    isServiceAttached,
                    serviceId,
                    dialogRef
                });
            });
        } else {
            this.openDeviceDialog({
                deviceDialogType,
                device,
                dialogComponentType: this.typeToComponentMap[deviceDialogType].component,
                isServiceAttached,
                serviceId,
                dialogRef
            });
        }
    };

    deleteDevice = (deviceArray) => {
        const allDevicesAreSkypeOrTeams = deviceArray.every((device) => {
            return ['TEAMS', 'LYNC'].includes(device.familyType);
        });
        const confirmMsg = this.messagesService.getConfirmMessage({
            entityName: 'device', entitiesArray: deviceArray,
            messagePrefix: allDevicesAreSkypeOrTeams ? '' : 'Some devices are managed by Floating License Manager and deleting them might affect device voice traffic.<br>'
        });

        this.delete({entityArray: deviceArray, confirmMessage: confirmMsg});
    };

    restoreDeviceWithFile = (entitiesArray, files) => {
        const serverCallback = (onSuccess, onFailure) => {
            const device = Array.isArray(entitiesArray) && entitiesArray.length > 0 ? entitiesArray[0] : [{}];
            const file = Array.isArray(files) && files.length > 0 ? files[0] : [{}];
            this.devicesRestService.restoreDeviceWithFile(onSuccess, onFailure, device, file);
        };

        const dialogData: ConfirmDialogData = {entitiesArray, confirmAlternativeText: 'restore configuration file to'};

        this.genericConfirmAction({
            serverCallback,
            dialogData,
            dialogConfig: {title: 'Restore Device', submitButtonText: 'restore'}
        });
    };

    deviceInfoAfterInit = (entityObject) => {
        entityObject && this.refreshSingleSelectionStatistics();
    };

    openChangeDeviceRegionDialog = (devices) => {
        const dialogConfig: DialogConfig = {
            cancelButtonText: 'Close',
            submitButtonText: 'Move',
            id: 'move-device-dialog',
            title: 'Move Device',
            width: 620
        };

        const entity = {tenantId: devices[0].tenantId, regionId: devices[0].regionId};

        const serverCallback = (onSuccess, onFailure) => {
            devices.forEach((device) => {
                this.devicesRestService.edit(onSuccess, onFailure, {
                    id: device.id,
                    regionId: entity.regionId
                }, device.id);
            });
        };
        const dialogComponentType = MoveDeviceDialogComponent;

        this.genericAction({serverCallback, dialogComponentType, dialogConfig, dialogData: {devices, entity}});
    };

    private getDialogOptionListsData = (selectedTopologyItem = undefined, dialogRef) => {
        const defer = PromiseService.defer();
        const success = (response: RestResponseSuccess) => {
            const dataObject = {
                softwareFileList: response.data.files,
                selectedTopologyItem// only for adding device for network-popup from tree
            };
            defer.resolve(dataObject);
        };

        const filter = {filter: {fileType: {value: 'VER_EMS_TYPE', operator: '!='}}};
        this.softwareManagerRestService.getFiles({
            success,
            failure: () => {
                dialogRef.close();
                defer.reject();
            },
            parameters: filter,
        });

        return defer.promise;
    };

    private prepareGeneralDeviceDialogData = (device = undefined) => {
        const deviceToSend = _.cloneDeep(device || this.deviceEmptyObj);

        device && this.cleanEmptyValues(deviceToSend);

        if (device === undefined) {
            delete deviceToSend.sbcInfo;
            delete deviceToSend.snmpInfo;
        }
        deviceToSend.productType = 'NONE_ACL_DEVICE';

        return deviceToSend;
    };

    private prepareDeviceDialogData = (device?) => {
        const deviceToSend = _.cloneDeep(device || this.teamsDeviceEmptyObj);
        delete deviceToSend.cloudUrl;

        return deviceToSend;
    };

    private prepareAudioCodesDeviceDialogData = ({dataObject, device = undefined, isMultiple = false, isServiceAttached = false, serviceId = undefined}) => {
        const isEdit = !!device;
        const additionalSettings: any = {
            initialConnectionEnabled: isEdit ? !!device.sbcInfo.preProvInfo : false,
            sbaEnabled: isEdit ? !!device.sbcInfo.SBAsInfo : false
        };

        const deviceToSend = _.cloneDeep(isEdit ? device : this.deviceEmptyObj);
        if (isEdit) {
            this.cleanEmptyValues(deviceToSend);
        } else {
            deviceToSend.sbcInfo.verifyCertificate = false;
        }


        const successMessage = isMultiple ? 'Multiple ACL devices added successfully' : undefined;
        const dialogData = {entity: deviceToSend, isMultiple, isServiceAttached, serviceId, additionalSettings, isEdit, successMessage};
        _.assign(dialogData, dataObject);

        return dialogData;
    };

    private prepareLyncDeviceDialogData = ({dataObject, device = undefined}) => {
        const SSLConnectionTypeList = MetadataService.getType('SSLConnectionType');
        const lyncDevice = device ? _.cloneDeep(device) : {};

        const sslOptionsList = [];
        _.forOwn(SSLConnectionTypeList, (value, key) => {
            sslOptionsList.push(SSLConnectionTypeList[key]);
        });

        const lyncProductTypesList = [];
        _.forOwn(this.audioCodesDevicesList, (value, key) => {
            if (key.includes('LYNC')) {
                lyncProductTypesList.push(this.audioCodesDevicesList[key]);
            }
        });

        const lyncFeDevices = this.devicesRestService.getAllEntities().filter((tmpDevice) => tmpDevice.productType === 'MS_LYNC_FE');
        const dialogData = {entity: lyncDevice, isEdit: !!device, lyncFeDevices, sslOptionsList, lyncProductTypesList};
        _.assign(dialogData, dataObject);

        return dialogData;
    };

    private openDeviceDialog = ({
        deviceDialogType = 'AC',
        dialogComponentType,
        device = undefined,
        dataObject = undefined,
        isMultiple = false,
        isServiceAttached = false,
        serviceId = undefined,
        dialogRef
    }) => {
        !isServiceAttached && this.checkIfRegionsExist(dialogRef);

        const isEdit = !!device;
        const id = device && device.id;

        let dialogData: any = {isEdit, entity: undefined};
        switch (deviceDialogType) {
            case 'AC':
                dialogData = this.prepareAudioCodesDeviceDialogData({dataObject, device, isMultiple, isServiceAttached, serviceId});
                break;
            case 'LYNC':
                dialogData = this.prepareLyncDeviceDialogData({dataObject, device});
                break;
            case 'TEAMS':
                dialogData.entity = this.prepareDeviceDialogData(device);
                break;
            case 'GENERIC':
                dialogData.entity = this.prepareGeneralDeviceDialogData(device);
                break;
        }

        const serverCallback = (onSuccess, onFailure) => {
            let deviceToServer = _.cloneDeep(dialogData.entity);
            if (deviceDialogType === 'AC' && isMultiple) {
                deviceToServer = this.multipleDeviceConverterService.convertMultipleACLDevicesToServer(deviceToServer, dialogData.additionalSettings);
            }

            if (deviceToServer.regionId) {
                delete deviceToServer.tenantId;
            }

            this.callToAddOrEditDevice(onSuccess, onFailure, deviceToServer, isEdit, id);
        };
        this.genericAction({serverCallback, dialogData, dialogComponentType, dialogRef});
    };

    private checkIfRegionsExist = (dialogRef) => {
        if (this.regionsRestService.getAllEntities().length === 0) {
            this.acDialogService.info('Can not add device when there are no regions');
            this.acDialogService.closeDialog(dialogRef);
            return;
        }
    };

    private callToAddOrEditDevice = (onSuccess, onFailure, deviceToSend, isEdit, id = undefined) => {
        if (isEdit) {
            this.devicesRestService.edit(onSuccess, onFailure, deviceToSend, id);
        } else {
            this.devicesRestService.add(onSuccess, onFailure, deviceToSend);
        }
    };
}
