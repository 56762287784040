import {Component, Inject, isDevMode} from '@angular/core';
import {Event, NavigationEnd, NavigationError, Router} from '@angular/router';
import {SessionHelperService} from './common/utilities/session-helper.service';
import {FilterObjectService} from './common/filters/filter-object.service';

import {
    AC_PROGRESS_LOADER_PATH, AcDialogService,
    AcNavAutoService,
    AcSvgComponent, AcTrackerService,
    BuildUrlsService,
    CommonNotifiersService,
    CommunicationConfigurationService,
    GeneralService,
    RestServerUrlService,
    SessionStorageService
} from 'ac-infra';

import {MatIconRegistry} from '@angular/material/icon';
import {ConnectionService} from './common/services/communication/connection.service';
import {SessionService} from './common/services/session.service';
import {AppService} from './app.service';
import {OriginalServerInfo, ServerInfoService} from "./common/services/server-info.service";
import {Title} from '@angular/platform-browser';
import {ConstantStringsService} from "./common/utilities/constant-strings.service";
import {
    ExternalApplicationsActionsService
} from "./system/configuration/configuration-actions/external-applications-actions.service";
import {AuthorizationService} from "./common/services/authorization.service";
import {BehaviorSubject} from "rxjs";
import {LogoDisplayService} from "./common/services/logo-display.service";
import {LoginService} from "./login/services/login.service";
import {ServerDownDialogComponent} from "./dialogs/server-down-dialog/server-down-dialog.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {

    serverInfoService = ServerInfoService;
    constructor(public generalService: GeneralService,
                private titleService: Title,
                private acNavAutoService: AcNavAutoService,
                private appService: AppService,
                private sessionService: SessionService,
                private sessionHelperService: SessionHelperService,
                private router: Router,
                private communicationConfigurationService: CommunicationConfigurationService,
                private filterObjectService: FilterObjectService,
                private iconRegistry: MatIconRegistry,
                private connectionService: ConnectionService,
                public loginService: LoginService,
                buildUrlsService: BuildUrlsService) {

        this.appService.updateSystemInfo();


        // if ((window as any).finishedLoading || isDevMode()) {
        //     window.document.body.classList.add('finishedLoading');
        // }

        if (isDevMode()) {
            const externalUrl = buildUrlsService.buildServletURL('getProxyIp');
            connectionService
                .get({uri: externalUrl, authMandatory: false, skipErrorHandler: true})
                .then((response) => {
                    RestServerUrlService.devModeProxyServer = response.data;
                });
        }

        window.document.body.classList.add('finishedLoading');
        (window as any).finishedLoading = true;

        router.events
            .subscribe((event: Event) => {
                if (event instanceof NavigationError) {
                    // eslint-disable-next-line no-debugger
                    debugger; // DO NOT REMOVE
                } else if (event instanceof NavigationEnd) {
                    const title = this.acNavAutoService.navs
                        .map(x => x.routeConfig.data?.text || x.routeConfig.path)
                        .filter(x => x !== '')
                        .join('/');
                    if (title && ServerInfoService.serverInfo) {
                        this.titleService.setTitle(ServerInfoService.systemType + ' - ' + location.hostname + ' ' + title);
                    }
                }
            });

        this.initCommunicationConfiguration();
        this.initOvocSVGs();

        const storedPDFMode = SessionStorageService.getData('PDFMode');
        this.generalService.PDFMode = storedPDFMode && storedPDFMode.PDFMode || false;
        sessionService.inActivityPath = 'sessionInactivityPeriodMin';


        this.sessionService.restoreSession();
    }



    private initCommunicationConfiguration() {
        this.communicationConfigurationService.setShortenURLFn(this.filterObjectService.getShortenURL);
        CommonNotifiersService.httpError$.subscribe((requestOptions: any) => {
            if (requestOptions.response.status === 410) {
                this.filterObjectService.addNewFilterAddRequestAgain(requestOptions);
            }
        });
    }

    loading = () => {
        return this.generalService.getProgress();
    };

    private initOvocSVGs() {
        AcSvgComponent.addPaths('audiocodes_live', 'assets/images/audiocodes_live.svg');
        AcSvgComponent.addPaths('ovoc_logo', 'assets/images/ovoc_logo.svg');
        AcSvgComponent.addPaths('ovoc_logo_only', 'assets/images/dashboard/ovoc_logo_only.svg');
        AcSvgComponent.addPaths('ovoc_text_only', 'assets/images/dashboard/ovoc_text_only.svg');

        AcSvgComponent.addPaths('endpoint', 'assets/images/topology/endpoint.svg');
        AcSvgComponent.addPaths('tenant', 'assets/images/topology/tenant.svg');
        AcSvgComponent.addPaths('region', 'assets/images/topology/region.svg');
        AcSvgComponent.addPaths('filter_list', 'assets/images/general/filter_list.svg');

        AcSvgComponent.addPaths(['TEAMS', 'LTC', 'OC'], 'assets/images/calls/TEAMS.svg');
        AcSvgComponent.addPaths(['WEBEX_CLOUD_CONNECT', 'WEBEX_LGW'], 'assets/images/services/webex.svg');
        AcSvgComponent.addPaths('LIVE_CX', 'assets/images/services/live-cx-colored.svg');
        AcSvgComponent.addPaths('GENERIC', 'assets/images/services/cloud.svg');
        AcSvgComponent.addPaths('LIVEHUB', 'assets/images/services/live-hub-colored.svg');
        AcSvgComponent.addPaths('HYBRID_ENTITIES', 'assets/images/services/hybrid.svg');
        AcSvgComponent.addPaths('MS_LYNC', 'assets/images/calls/MS_LYNC.svg');
        AcSvgComponent.addPaths('TEAMS_DEVICE', 'assets/images/devices/TEAMS_DEVICE.svg');

        AcSvgComponent.addPaths('MS_LYNC_CONFERENCE', 'assets/images/calls/MS_LYNC_CONFERENCE.svg');
        AcSvgComponent.addPaths('phone', 'assets/images/calls/detailsPage/phone.svg');
        AcSvgComponent.addPaths('pstn', 'assets/images/calls/detailsPage/pstn.svg');
        AcSvgComponent.addPaths('pstn_phone', 'assets/images/calls/detailsPage/pstn_phone.svg');
        AcSvgComponent.addPaths('SBCCloud', 'assets/images/calls/detailsPage/SBCCloud.svg');
        AcSvgComponent.addPaths('testSBCCloud', 'assets/images/calls/detailsPage/testSBCCloud.svg');

        // devices
        AcSvgComponent.addPaths('SBC', 'assets/images/devices/SBC.svg');
        AcSvgComponent.addPaths('LYNC_EDGE', 'assets/images/devices/LYNC_EDGE.svg');
        AcSvgComponent.addPaths('LYNC_MEDIATION', 'assets/images/devices/LYNC_MEDIATION.svg');
        AcSvgComponent.addPaths('vaic', 'assets/images/table/vaic.svg');
        AcSvgComponent.addPaths('VAIC', 'assets/images/devices/VAIC.svg');

        // dashboard
        AcSvgComponent.addPaths('sites', 'assets/images/dashboard/sites.svg');
        AcSvgComponent.addPaths('links', 'assets/images/dashboard/links.svg');
        AcSvgComponent.addPaths('UMP', 'assets/images/dashboard/UMP.svg');
        AcSvgComponent.addPaths('range', 'assets/images/dashboard/range.svg');
        AcSvgComponent.addPaths('SmartTAP', 'assets/images/dashboard/SmartTAP.svg');
        AcSvgComponent.addPaths('voiceAI', 'assets/images/dashboard/voiceAI.svg');
        AcSvgComponent.addPaths('ZOOM', 'assets/images/dashboard/ZOOM.svg');
        AcSvgComponent.addPaths('MEETING_INSIGHTS', 'assets/images/network/MEETING_INSIGHTS.svg');
        AcSvgComponent.addPaths('STNG', 'assets/images/network/STNG.svg');
        AcSvgComponent.addPaths('ERROR-ZOOM', 'assets/images/dashboard/ERROR-ZOOM.svg');
        AcSvgComponent.addPaths('Microsoft', 'assets/images/dashboard/Microsoft.svg');
        AcSvgComponent.addPaths('genericApplication', 'assets/images/dashboard/generic_application.svg');

        // dashboard groups
        AcSvgComponent.addPaths('channel', 'assets/images/dashboard/groups/channel.svg');
        AcSvgComponent.addPaths(['services', 'service', 'service_teams'], 'assets/images/dashboard/groups/services.svg');
        AcSvgComponent.addPaths(['lcCustomer', 'customer', 'customer_teams'], 'assets/images/dashboard/groups/lcCustomer.svg');
        AcSvgComponent.addPaths('DIDs', 'assets/images/dashboard/groups/DIDs.svg');
        AcSvgComponent.addPaths('user_location', 'assets/images/dashboard/groups/user_location.svg');
        AcSvgComponent.addPaths('users', 'assets/images/dashboard/groups/users.svg');
        AcSvgComponent.addPaths('deviceManager', 'assets/images/dashboard/DM.svg');

        AcSvgComponent.addPaths('Analytics', 'assets/images/dashboard/info/Analytics.svg');
        AcSvgComponent.addPaths('DeviceManager', 'assets/images/dashboard/info/DeviceManager.svg');
        AcSvgComponent.addPaths('Management', 'assets/images/dashboard/info/Management.svg');
        AcSvgComponent.addPaths('OVOCUpdate', 'assets/images/dashboard/info/OVOCUpdate.svg');
        AcSvgComponent.addPaths('QOE', 'assets/images/dashboard/info/QoE.svg');
        AcSvgComponent.addPaths('Security', 'assets/images/dashboard/info/Security.svg');

        AcSvgComponent.addPaths('audio', 'assets/svgs/call/audio.svg');
        AcSvgComponent.addPaths('screen_sharing', 'assets/svgs/call/screen_sharing.svg');
        AcSvgComponent.addPaths('video', 'assets/svgs/call/video.svg');
        AcSvgComponent.addPaths('SMART_TAP', 'assets/images/table/vaic.svg');
        AcSvgComponent.addPaths('DM', 'assets/images/table/deviceManager.svg');

        AcSvgComponent.addPaths('reportBar', 'assets/images/reports/reportBar.svg');
        AcSvgComponent.addPaths('reportLine', 'assets/images/reports/reportLine.svg');
        AcSvgComponent.addPaths('reportStackBar', 'assets/images/reports/reportStackBar.svg');
        AcSvgComponent.addPaths('reportPie', 'assets/images/reports/reportPie.svg');

        AcSvgComponent.addPaths('reportDynamic', 'assets/images/reports/reportDynamic.svg');

        AcSvgComponent.addPaths('call_connection_type_mobile', 'assets/images/calls/connectionTypes/call_connection_type_mobile.svg');
        AcSvgComponent.addPaths('call_connection_type_teams_group', 'assets/images/calls/connectionTypes/call_connection_type_teams_group.svg');
        AcSvgComponent.addPaths('call_connection_type_teams_group_originator', 'assets/images/calls/connectionTypes/call_connection_type_teams_group_originator.svg');
        AcSvgComponent.addPaths('call_connection_type_tunnel', 'assets/images/calls/connectionTypes/call_connection_type_tunnel.svg');
        AcSvgComponent.addPaths('call_connection_type_unknown', 'assets/images/calls/connectionTypes/call_connection_type_unknown.svg');
        AcSvgComponent.addPaths('call_connection_type_wifi', 'assets/images/calls/connectionTypes/call_connection_type_wifi.svg');
        AcSvgComponent.addPaths('call_connection_type_wired', 'assets/images/calls/connectionTypes/call_connection_type_wired.svg');

        AcSvgComponent.addPaths('correlated-call', 'assets/images/calls/correlated-call.svg');
        AcSvgComponent.addPaths('call', 'assets/images/calls/call.svg');

        AcSvgComponent.addPaths('topology', 'assets/images/topology/topology.svg');

        AcSvgComponent.addPaths('counter', 'assets/images/statistics/counter.svg');
        AcSvgComponent.addPaths('resize', 'assets/images/statistics/resize.svg');
        AcSvgComponent.addPaths('monitoring', 'assets/images/statistics/monitoring.svg');

        // External Reports: (Power BI)
        AcSvgComponent.addPaths('externalReport', 'assets/svgs/externalReport.svg');

    }
}
