import {Inject, Injectable} from '@angular/core';
import {
    AC_PROGRESS_LOADER_PATH,
    AcDialogService,
    AcTrackerService,
    AutoRefreshService,
    DynamicTabsService,
    GeneralService,
    RestServerUrlService
} from 'ac-infra';
import {WebSocketNotification} from './common/services/ws/ws.notification.service';
import {CachedConnection} from './common/services/communication/cached-connection.service';
import {LogoDisplayService} from './common/services/logo-display.service';
import {WorkspaceManagerService} from './workspace/workspace-manager.service';
import {LoginService} from './login/services/login.service';
import {WebSocketService} from './common/services/ws/ws.service';
import {Router} from '@angular/router';
import {SessionService} from './common/services/session.service';
import {UnlockService} from './login/services/unlock.service';
import {NavigationService} from './common/utilities/navigation.service';

import {MatomoTracker} from '@ngx-matomo/tracker';
import {TenantScopeService} from './common/services/tenant-scope.service';
import {WsEntitiesService} from "./common/services/communication/ws-entities.service";
import {TopologyTreeService} from './network/services/topology/topology-tree.service';
import {LiveCloudTopologyTreeService} from './network/services/topology/live-cloud-topology-tree.service';
import {OriginalServerInfo, ServerInfoService} from "./common/services/server-info.service";
import {AuthorizationService} from "./common/services/authorization.service";
import {SessionHelperService} from "./common/utilities/session-helper.service";
import {
    ExternalApplicationsActionsService
} from "./system/configuration/configuration-actions/external-applications-actions.service";
import {ConstantStringsService} from "./common/utilities/constant-strings.service";
import {ServerDownDialogComponent} from "./dialogs/server-down-dialog/server-down-dialog.component";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(
        private sessionService: SessionService,
        private generalService: GeneralService,
        private restServerURLService: RestServerUrlService,
        private webSocketNotification: WebSocketNotification,
        private dynamicTabsService: DynamicTabsService,
        private cachedConnection: CachedConnection,
        private autoRefreshService: AutoRefreshService,
        private logoDisplayService: LogoDisplayService,
        private workspaceManagerService: WorkspaceManagerService,
        private topologyTreeService: TopologyTreeService,
        private liveCloudTopologyService: LiveCloudTopologyTreeService,
        private loginService: LoginService,
        private unlockService: UnlockService,
        private webSocketService: WebSocketService,
        private acDialogService: AcDialogService,
        private navigationService: NavigationService,
        private router: Router,
        private wsEntitiesService: WsEntitiesService,
        private acTrackerService: AcTrackerService,
        private tenantScopeService: TenantScopeService,
        private readonly matomoTracker: MatomoTracker,
        @Inject(AC_PROGRESS_LOADER_PATH) private progressLoaderPath: BehaviorSubject<string>
    ) {

        this.acTrackerService.trackEvent$.subscribe({
            next: ({category, action, name, value}) => {
                this.matomoTracker.trackEvent(category, action, name, value);
            }
        });

        this.sessionService.sessionLocked$.subscribe(() => {
            this.unlockService.lock();
            this.autoRefreshService.stop();
            this.router.navigateByUrl('login/unlock');
        });

        this.sessionService.sessionTerminated$.subscribe(() => {
            // this.systemInitialized = false;
            this.tenantScopeService.updateTenantOrGlobalScope(undefined);
            this.generalService.systemInitialized = null;
            this.restServerURLService.init(); // FOR STORING HTTP OR HTTPS ON SESSION STORAGE "communication.protocol"
            this.webSocketNotification.clearAll();
            this.dynamicTabsService.clear();
            this.cachedConnection.clearCache();
            this.autoRefreshService.stop();
            this.logoDisplayService.clear();
            this.workspaceManagerService.clearActiveWorkspace();
            this.wsEntitiesService.reset();
            this.topologyTreeService.clearTreeNodes();
            this.liveCloudTopologyService.clearTreeNodes();
            this.loginService.logout();
            this.webSocketService.closeWsConnectionDialog();
            this.acDialogService.closeAllDialogs();
            this.router.navigateByUrl('login');
        });
    }

    updateSystemInfo = () => {
        // const url = this.restServerURLService.getRestServerURL() + 'status/rest';

        const success = (response: OriginalServerInfo) => {
            this.acTrackerService.setAcTrackerIsActive(response.allowIntegrationWithUxApp);
            ServerInfoService.serverInfo = response;
            ServerInfoService.oneLiveMode = response.ovocMode === 'OneLive';
            this.sessionService.setServerVersion(ServerInfoService.serverInfo.ovocVersion);
            AuthorizationService.oneLiveMode = ServerInfoService.oneLiveMode;
            SessionHelperService.setSecurityLevels(ServerInfoService.oneLiveMode);

            if (!response.branding) {
                this.progressLoaderPath.next('assets/animations/ovoc-loader.json');
            }

            ServerInfoService.systemType = response.branding || response.whiteLabeling?.name || (ServerInfoService.oneLiveMode ? 'AudioCodes Live' : 'OVOC');
            if (ServerInfoService.serverInfo.branding === 'Nuera') {
                ExternalApplicationsActionsService.EXTERNAL_APPLICATION_OBJ.arm.disabledLink = 'https://www.nuera.com/';
            }

            if (ServerInfoService.oneLiveMode) {
                ConstantStringsService.linkOrProviderSide = 'SIP Connection';
                ConstantStringsService.deviceOrTeamsSide = 'Device';
            }

            this.logoDisplayService.updateLogos();

            if(response.operatorAuthenticationMode === 'IAM'){
                this.loginService.openMFALoginPage();
            }

            Object.freeze(ServerInfoService.serverInfo);
        };

        ServerInfoService.serverInfoPromise = (window as any).__ovoc.restResponse;
        ServerInfoService.serverInfoPromise.then(success).catch(this.serverFailure);
        return ServerInfoService.serverInfoPromise;
    };

    serverFailure = (error) => {
        this.acDialogService.open(ServerDownDialogComponent, {
            noCancelButton: true,
            preventEscape: true
        });
    };

}
