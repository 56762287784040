import {Injectable} from '@angular/core';
import {AcGeoEventsService} from '../../components/ac-geo/services/ac-geo-events.service';
import {NetworkFilterFormatterService} from '../network-filter-formatter.service';
import {NetworkRest} from '../../../common/server-actions/network-rest';


@Injectable({providedIn: 'root'})
export class ServicesRestService extends NetworkRest {

    constructor(networkFilterFormatter: NetworkFilterFormatterService) {
        super({url: 'topology/services', entityFilterFormatter: networkFilterFormatter, filterEventName: 'NetworkFilter', entityType: 'services'});
    }
}
