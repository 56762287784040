export interface OriginalServerInfo {
    branding?: string;
    saasMode: boolean;
    meteringEnabled: boolean;
    operatorAuthenticationMode: string;
    ovocMode: string;
    ovocVersion: string;
    restVersion: string;
    status: string;
    allowIntegrationWithUxApp?: boolean;
    whiteLabeling?: any;
    serviceAvailabilityEnabled?: boolean;
    ovocSystemFileLogoId: number;
}

export class ServerInfoService {
    static serverInfoPromise: any;
    static serverInfo: OriginalServerInfo;

    static oneLiveMode: boolean;
    static isHybridOperator: boolean;
    static systemType: string;
}
