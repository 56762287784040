import {Injectable, NgZone} from '@angular/core';
import {Location} from '@angular/common';
import {Workspace, WorkspaceRestService} from './workspace-rest.service';
import {AcDialogService, AcNavAutoService, AcTableActions, ArrayUtil, GeneralService, SessionStorageService, ThrottleClass} from 'ac-infra';
import {workspaceVersionObj} from './workspace-versions.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngxs/store';
import {SessionService} from '../common/services/session.service';
import {workspaceMigrations} from './migrations/workspace-migrations';
import {FilterState} from '../common/components/ac-filter/services/ac-filter-state.service';
import {cloneDeep, forOwn, isEqual, pickBy} from 'lodash';
import {WsEntitiesService} from '../common/services/communication/ws-entities.service';


export const DEFAULT_WORKSPACE_ID = -1;

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class WorkspaceManagerService {

    private activeWorkspace: Workspace;
    private autoSaveThrottle: ThrottleClass;

    constructor(private workspaceRestService: WorkspaceRestService,
                private sessionService: SessionService,
                private ngLocation: Location,
                private _ngZone: NgZone,
                private acDialogService: AcDialogService,
                private generalService: GeneralService,
                private wsEntitiesService: WsEntitiesService,
                private acNavAutoService: AcNavAutoService,
                private store: Store) {

        (window as any)._getExportData = this.getExportData;
        (window as any)._loadWorkspace = this.loadWorkspace;
    }

    get sessionWorkspaceName(): string {
        return this.sessionService.activeSession.name + '_workspace';
    }

    get sessionActiveWorkspaceId(): number {
        return this.sessionService.activeSession?.activeWorkspaceId;
    }

    get isActiveWorkspace(): boolean {
        return this.activeWorkspace?.isActive;
    }

    get isAutoSaveEnabled(): boolean {
        return this.activeWorkspace?.autoSave;
    }

    initializeAutoSave() {
        if (this.autoSaveThrottle) {
            return;
        }

        this.autoSaveThrottle = new ThrottleClass({
            callback: () => {
                if (!this.activeWorkspace?.autoSave) {
                    return;
                }

                const exportCandidateData = this.getExportData();
                if (isEqual(exportCandidateData, this.activeWorkspace.data)) {
                    return;
                }
                this.activeWorkspace.data = exportCandidateData;
                this.export({
                    data: exportCandidateData,
                    id: this.activeWorkspace?.id
                });
            },
            destroyComponentOperator: untilDestroyed(this),
            maxRecurrentTime: 5000, // 30000
            debounce: 1000,
            maxDebounceTime: 15000,
        });

        SessionStorageService.setData$.subscribe(() => this._ngZone.runOutsideAngular(() => {
            this.activeWorkspace?.id > 0 && this.autoSaveThrottle.tick();
        }));
    }

    updateSessionActiveWorkspaceId = (workspaceId) => this.sessionService.assignToSession({activeWorkspaceId: workspaceId});

    exportTopologyFilter = (scopedFilter) => {
        if (!scopedFilter) {
            return;
        }
        Object.entries<any>(scopedFilter).forEach(([filterType, filterValue]) => {
            filterValue.Topology && Object.entries<any[]>(filterValue.Topology).forEach(([entityType, entities]) => {
                filterValue.Topology[entityType] = entities?.filter((entity) => !!entity?.id)?.map(entity => entity.id);
            });

            filterValue.LiveCloudTopology && Object.entries<any[]>(filterValue.LiveCloudTopology).forEach(([entityType, entities]) => {
                filterValue.LiveCloudTopology[entityType] = entities?.filter((entity) => !!entity?.id)?.map(entity => entity.id);
            });
        });
    };

    getExportData = () => {
        const sessionKeys = Object.getOwnPropertyNames(sessionStorage);

        const parsedSessionStorage: any = ArrayUtil.arrayToObject(sessionKeys, (acc, sessionKey) => {
            const sessionValue = sessionStorage[sessionKey];
            if (!sessionValue || sessionValue === 'undefined') {
                return
            }
            const parsedSessionValue = JSON.parse(sessionValue);
            if (['session', 'communication.protocol'].includes(sessionKey)) {
                return;
            }
            if (parsedSessionValue && sessionKey.includes('ScopeFilter')) {
                this.exportTopologyFilter(parsedSessionValue);
            }
            acc[sessionKey] = parsedSessionValue;
        }, {...workspaceVersionObj});


        return parsedSessionStorage;
    };

    export = async ({data, ...workspace}: Workspace = {}, {activateOnCreate = false} = {}) => {
        const activeWorkspaceId = workspace.id || this.activeWorkspace?.id;
        data = data || this.getExportData();

        if (!activeWorkspaceId || activeWorkspaceId <= 0) {
            const createdWorkspace = await this.workspaceRestService.createWorkspace({name: this.sessionWorkspaceName, data, ...workspace});
            activateOnCreate && this.activateWorkspace(createdWorkspace.id);
            return createdWorkspace;
        }
        return await this.workspaceRestService.updateWorkspaceById(activeWorkspaceId, {data, ...workspace});
    };

    migrateWorkspace = (dataVersion, workspaceData) => {
        if (workspaceVersionObj.workspaceVersion <= dataVersion || !workspaceData) {
            return workspaceData;
        }

        const nextVersion = dataVersion + 1;
        workspaceData = workspaceMigrations[nextVersion]?.(workspaceData) || workspaceData;
        delete workspaceData?.infoPanel;
        return this.migrateWorkspace(nextVersion, workspaceData);
    };


    loadFiltersTopologyEntities = (workspaceData) => {
        const filterName = FilterState.getStorageFilterName(workspaceData.tenantScope);
        const pinned = cloneDeep(workspaceData?.[filterName]?.pinned);
        const unpinnedFilters = cloneDeep(pickBy(workspaceData?.[filterName], (value, key) => key.startsWith(FilterState.UNPINNED_STATE)) || {});

        [pinned, ...Object.values(unpinnedFilters)].forEach((filters: any) => {
            if (!filters) {
                return;
            }

            Object.entries(filters.Topology || {}).forEach(([entityType, entitiesIds]) => {
                filters.Topology[entityType] = this.wsEntitiesService.getEntitiesArray(entityType + 's', entitiesIds);
            });

            Object.entries(filters.LiveCloudTopology || {}).forEach(([entityType, entitiesIds]) => {
                filters.LiveCloudTopology[entityType] = this.wsEntitiesService.getEntitiesArray(entityType + 's', entitiesIds);
            });
        });

        const topologyFilters = {[filterName]: {...workspaceData?.[filterName], pinned, ...unpinnedFilters}};

        return {...workspaceData, ...topologyFilters};
    };

    loadWorkspace = ({workspaceVersion, ...workspaceData}: any, refreshPage = false) => {
        if (!workspaceVersion) {
            workspaceVersion = 0;
        }
        refreshPage && this.emptyLocalWorkspace(false);

        workspaceData = this.migrateWorkspace(workspaceVersion, workspaceData);
        workspaceData = this.loadFiltersTopologyEntities(workspaceData);
        this.loadWorkspaceData(workspaceData);
        this.store.dispatch(new AcTableActions.SetTableState(workspaceData.tableState));
        refreshPage && this.refreshPageToRoute(workspaceData.lastStaticState || this.acNavAutoService.lastStaticPage);
    };

    import(workspaceId, refreshPage = false, workspaceLoaded = false) {
        return this.workspaceRestService.getWorkspaceById(workspaceId, (workspace: Workspace) => {
            this.activeWorkspace = workspace;
            !workspaceLoaded && this.loadWorkspace(workspace.data || {}, refreshPage);
        });
    }

    initWorkspace = async () => {
        const workspaceLoaded = this.sessionService.activeSession.workspaceLoaded;
        const workspaceSessionId = this.sessionActiveWorkspaceId;
        const workspaces = await this.workspaceRestService.getWorkspaceState();

        const workspace: Workspace = workspaces?.[0] || {};
        const workspaceId = workspace.isActive ? workspace.id : workspaceSessionId;
        if (workspaceId > 0) {
            return this.import(workspaceId, false, workspaceLoaded);
        } else if (workspaceId === -2) {
            return this.export({id: -2, autoSave: true, isActive: true}, {activateOnCreate: true});
        }
        return;
    };

    importWorkspace = ({workspaceId = undefined, refreshPage = false} = {}) => {
        const activeWorkspaceId = workspaceId || this.sessionActiveWorkspaceId;

        if (activeWorkspaceId > 0 && !GeneralService.testMode) {
            this.import(activeWorkspaceId, refreshPage, false);
        }
    };

    async setWorkspaceAutoSave(autoSave: boolean) {
        const activeWorkspaceId = this.activeWorkspace?.id;
        if (!activeWorkspaceId || activeWorkspaceId < 0) {
            return;
        }
        return await this.toggleWorkspaceAutoSaveById(activeWorkspaceId, autoSave);
    }

    async toggleWorkspaceAutoSaveById(workspaceId, autoSave: boolean, onSuccess = () => undefined) {
        return await this.workspaceRestService.updateWorkspaceById(workspaceId, {autoSave}, {
            success: () => {
                onSuccess();
                if (!this.activeWorkspace) {
                    return;
                }

                this.activeWorkspace.autoSave = autoSave;
                if (autoSave && workspaceId === this.activeWorkspace.id) {
                    this.autoSaveThrottle.tick();
                }
            }
        });
    }

    emptyLocalWorkspace(refreshPage = false) {
        const lastStaticState = SessionStorageService.getData(AcNavAutoService.LAST_STATIC_ROUTE);

        SessionStorageService.clearAllData();
        SessionStorageService.setData('session', this.sessionService.activeSession);

        refreshPage && this.refreshPageToRoute(lastStaticState);
    }


    confirmWorkspaceActivation(workspaceId) {
        return this.acDialogService.confirm('Activating workspace will dismiss unsaved changes on active workspace', {
            onSubmit: () => {
                if (workspaceId < 0) {
                    this.deactivateLoadedWorkspace();
                } else {
                    this._activateWorkspace(workspaceId);
                }
                return true;
            },
            onCancel: () => false,
        });
    }

    activateWorkspace = (workspaceId) => {
        const success = () => this._activateWorkspace(workspaceId);

        if (this.activeWorkspace?.autoSave) {
            this.export().then(success);
            return;
        }
        success();
    };


    deactivateLoadedWorkspace({loadEmpty = true} = {}) {
        const activeWorkspaceId = this.activeWorkspace?.id || DEFAULT_WORKSPACE_ID;

        const deactivateWorkspaceUI = () => {
            this.updateSessionActiveWorkspaceId(DEFAULT_WORKSPACE_ID);
            loadEmpty && this.emptyLocalWorkspace(true);
        };

        if (activeWorkspaceId <= 0) {
            deactivateWorkspaceUI();
            return;
        }

        this.workspaceRestService.updateWorkspaceById(activeWorkspaceId, {isActive: false}, {
            success: deactivateWorkspaceUI,
        });
    }

    deleteWorkspace(workspaceId) {
        this.workspaceRestService.deleteById({
            id: workspaceId,
            success: () => {
                this.sessionService.assignToSession({activeWorkspaceId: -1, workspaceLoaded: false});
                this.clearActiveWorkspace();
                this.deactivateLoadedWorkspace();
            }
        });
    }

    clearActiveWorkspace() {
        this.activeWorkspace = null;
    }

    private _activateWorkspace(workspaceId) {
        if (workspaceId === this.activeWorkspace?.id) {
            this.importWorkspace({workspaceId, refreshPage: true});
        } else {
            this.workspaceRestService.updateWorkspaceById(workspaceId, {isActive: true}, {
                success: () => {
                    this.updateSessionActiveWorkspaceId(workspaceId);
                    this.importWorkspace({workspaceId, refreshPage: true});
                }
            });
        }
    }

    private loadWorkspaceData(data: any) {
        forOwn(data, (value, key) => {
            if (value === null || value === undefined) {
                return;
            }
            SessionStorageService.setData(key, value);
        });
    }

    private refreshPageToRoute(route) {
        location.pathname = (this.ngLocation as any)._basePath + route;
    }

}
