export enum statuses {
    Create = 'Create',
    Update = 'Update',
    Delete = 'Delete',
    Notifications = 'Notifications',
    FullSync = 'FullSync',
    MeteringSync = 'MeteringSync'
}

export const WSTypes = {
    Cluster: 'clusters',
    Tenant: 'tenants',
    Region: 'regions',
    Site: 'sites',
    Device: 'devices',
    Link: 'links',
    Group: 'groups',
    Service: 'services',
    Customer: 'lcCustomers',
    Channel: 'channels',
    ARM: 'externalApplications',
    Masterscope: 'externalApplications',
} as const;

export type WSServerType = keyof typeof WSTypes;
export type WSClientType = typeof WSTypes[keyof typeof WSTypes];

export class WSMessage {
    static readonly wsObjects = WSTypes;

    name: string;
    protocol: string;
    description: string;
    status: string;
    messageType: statuses;
    module: string;
    arrivalTime: number;
    entities: any[];
    entitiesIds: any[];
    entityType: string;
    entityTypeName: string;

    constructor() {
        this.arrivalTime = Date.now();
    }

    wsEntityTypeMapper = (entityType: string) => {
        if(!entityType){
            return ;
        }

        if(['arm', 'masterscope', 'zoompeering'].includes(entityType.toLowerCase())){
            return 'externalApplications';
        }

        return WSTypes[entityType];
    }

    prepareMessage = () => {
        this.entityTypeName = this.wsEntityTypeMapper(this.entityType);
        if (this.entities) {
            this.entitiesIds = this.entities.map((entity) => entity.entityId);
        }
    };
}
