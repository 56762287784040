import {Injectable} from '@angular/core';
import {AcDialogService, AcNavAutoService, AcTableActions, CommonNotifiersService, DynamicTabsService, PromiseService, SessionStorageService} from 'ac-infra';
import {TenantsRestService} from '../../network/services/apis/tenants-rest.service';
import {AuthorizationService} from './authorization.service';
import {NavigationService} from '../utilities/navigation.service';
import {SelectTenantScopeDialogComponent} from '../../network/dialogs/select-tenant-scope-dialog/select-tenant-scope-dialog.component';
import {SessionService} from './session.service';
import {LogoDisplayService} from './logo-display.service';
import {RestResponseSuccess} from '../server-actions/rest';
import {cloneDeep} from 'lodash';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Store} from '@ngxs/store';
import {FilterState} from '../components/ac-filter/services/ac-filter-state.service';
import {AnalyticsRestService} from '../../statistics/analytics/services/analytics-rest.service';
import {ExternalApplication} from '../state/external-application.actions';
import {LiveCloudTopologyTreeService} from '../../network/services/topology/live-cloud-topology-tree.service';
import {TopologyTreeService} from '../../network/services/topology/topology-tree.service';
import {ServerInfoService} from './server-info.service';
import {ChannelsRestService} from '../../network/services/apis/channels-rest.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
    })
export class TenantScopeService {

    public tenantScopeName = '';
    tenantScopeReady = false;

    constructor(private tenantsRestService: TenantsRestService,
                private channelsRestService: ChannelsRestService,
                private authorizationService: AuthorizationService,
                private navigationService: NavigationService,
                private sessionService: SessionService,
                private logoDisplayService: LogoDisplayService,
                private dynamicTabsService: DynamicTabsService,
                private acNavAutoService: AcNavAutoService,
                private store: Store,
                private filterState: FilterState,
                private topologyTreeService: TopologyTreeService,
                private liveCloudTopologyService: LiveCloudTopologyTreeService,
                private analyticsRestService: AnalyticsRestService,
                private acDialogService: AcDialogService) {
        CommonNotifiersService.entitiesAreReady$.pipe(untilDestroyed(this)).subscribe((data) => {
            this.updateTenantScopeName();
        });
    }

    initTenantScope = () => {
        const defer = PromiseService.defer();

        const storageTenantScope = SessionStorageService.getData('tenantScope');

        if (storageTenantScope) {
            this.handleTenantLogin(defer, storageTenantScope);
        } else if (this.authorizationService.isSystemLevel()) {
            this.updateTenantOrGlobalScope(null);
            defer.resolve();
        } else {
            this.handleTenantLogin(defer);
        }

        return defer.promise;
    };

    handleTenantLogin = (defer, storageTenantScope?) => {
        this.tenantsRestService.get({extendParameters: true}).then((response: RestResponseSuccess) => {
            const tenants = response?.data?.tenants || [];
            if (!tenants || tenants.length === 0) {
                this.noTenantsFound(defer);
            } else if (tenants?.length === 1) {// maybe the tenant is not equal to the one stored in the workspace
                this.updateTenantOrGlobalScope(tenants[0].id);
                defer.resolve();
            } else if (storageTenantScope) {
                const tenantFromStorage = tenants.filter((tenant) => tenant.id === storageTenantScope);
                if (tenantFromStorage?.length === 1) {
                    this.updateTenantOrGlobalScope(tenantFromStorage[0].id);
                    defer.resolve();
                } else {// maybe the tenant is not equal to the one stored in the workspace
                    this.openSelectTenantDialog(true, tenants, defer);
                }
            } else {
                this.openSelectTenantDialog(true, tenants, defer);
            }
        });
    };

    noTenantsFound = (defer) => {
        this.sessionService.endSession();
        this.acDialogService.info('No tenant assigned please contact your administrator.');
        defer.resolve();
    };

    openSelectTenantDialog = (isLogin = false, tenants?, defer?) => {
        tenants = tenants || cloneDeep(this.tenantsRestService.getAllEntities(undefined, true));

        const currentTenantScope = AuthorizationService.tenantScope || -1;
        const dialogData = {
            entity: {tenantId: currentTenantScope},
            tenants, sortTenants: true, title: 'Choose Tenant Scope', noCancelButton: false
        };
        this.acDialogService.open(SelectTenantScopeDialogComponent, {
            onSubmit: () => {
                this.onSubmitTenantScope(dialogData.entity.tenantId, isLogin, defer);
            },
            noCancelButton: isLogin,
            preventEscape: isLogin,
            dialogData,
        });
    };

    onSubmitTenantScope = async (tenantId, isLogin = false, defer?) => {
        if(tenantId !== -1){
            this.filterState.moveFiltersIn(tenantId);
        }else{
            this.filterState.moveFiltersOut();
        }

        this.updateTenantOrGlobalScope(tenantId > 0 ? tenantId : null, isLogin);
        await this.analyticsRestService.updateScopeLicense();
        if(!this.authorizationService.validForMonitorLinkOrOneLiveTenantUserOrGroupUsers()){
            this.store.dispatch(new ExternalApplication.FetchService());
        }
        defer?.resolve();
    };

    updateTenantOrGlobalScope = (value, isFromLogin = true) => {
        this.tenantScopeReady = false;
        AuthorizationService.tenantScope = value;
        SessionStorageService.setData('tenantScope', AuthorizationService.tenantScope);
        this.updateTenantScopeName();
        const tabs = this.dynamicTabsService.currentTabs;
        if (!isFromLogin) {
            this.topologyTreeService.clearTreeNodes();
            this.liveCloudTopologyService.clearTreeNodes();
            if (tabs.length > 0) {
                setTimeout(() => {
                    this.dynamicTabsService.clear();
                });
            }
            this.store.dispatch(new AcTableActions.ClearAllTablesCurrentPage());
        }


        if (this.sessionService.activeSession.isSystemOperator) {
            if (value === null) {// global scope
                this.authorizationService.securityLevel = this.sessionService.activeSession.securityLevel;
            } else {
                this.authorizationService.securityLevel = 'TENANT_' + this.sessionService.activeSession.originalSecurityLevel;
            }
        }

        setTimeout(() => {
            if (!isFromLogin) {
                this.acNavAutoService.gotoLastStaticState(true).then(() => {
                    this.navigationService.reload();
                });
                this.logoDisplayService.updateLogos();
            }
            ServerInfoService.isHybridOperator = this.isOperatorIsAttachedToHybrid();
            this.tenantScopeReady = true;
        });
    };

    updateTenantScopeName = () => {
        const tenant = this.tenantsRestService.getEntityById(AuthorizationService.tenantScope);
        this.tenantScopeName = AuthorizationService.tenantScope ? (tenant?.name || '') : 'Global';

        return this.tenantScopeName;
    };

    isOperatorIsAttachedToHybrid = () => {
        if(!ServerInfoService.oneLiveMode || this.authorizationService.isSystemLevel()){
            return false;
        }else if(this.authorizationService.isTenantUser()){
            return this.isHybridEnabled(this.tenantsRestService.getAllEntities());
        }else if(this.authorizationService.isChannelLevel()){
            return this.isHybridEnabled(this.channelsRestService.getAllEntities());
        }else if(this.authorizationService.validForCustomerOperator()){
            const tenants = this.tenantsRestService.getAllEntities();
            const channels = this.channelsRestService.getAllEntities();

            return this.isHybridEnabled(channels?.length === 1 ? channels : tenants);
        }

        return false;
    };

    isHybridEnabled = (entities) => {
        return entities?.length === 1 && entities[0]?.services?.HYBRID_ENTITIES?.enabled;
    };
}
