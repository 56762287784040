<div class="ac-table flexColumnParent"
     [class.no-user-selection]="tableCellContextIndex >= 0"
     [class.visibilityHidden]="!viewPortVisible"
     [class.finishLoading]="!loading"
     [attr.id]="tableId"
     (wheel)="onWheelUpdateHorizontalScroller($event, horizontalScroller)"
     [attr.tabindex]="0"
     (focus)="toggleFocus(true)"
     (blur)="toggleFocus(false)"
     #tableContainer>

    <div class="header-table flexItem" #headerContainer>
        <table [class.visibilityHidden]="!isHeaderVisible || !initialized.closed"
               [style.padding-right.px]="viewportScrollBarWidth | acFunc : acTableService.getLimitedWidth"
               (mousedown)="tableMousedown($event)"
               (dblclick)="dblClick($event, container, headerContainer)"
               #headerTable>

            <thead ac-table-header [class.rtl]="rtl" [columns]="_columns" (columnSort)="onColumnSort($event)" #acTableHeader></thead>
        </table>
    </div>

    <virtual-scroller class="body-table virtual-scroll flexItemBiggest"
                      [class.visibilityHidden]="!isHeaderVisible || !isBodyVisible"
                      [items]="_rows | acFunc : getViewPortRows: collapsedGroups: filters"
                      [enableUnequalChildrenSizes]="true"
                      [bufferAmount]="virtualScrollerBufferAmount"
                      (vsEnd)="updateInfiniteScroll($event)"
                      #vsComponent>

        <div *ngIf="!loading && !vsComponent.viewPortItems?.length" class="no-data-placeholder">
            <ng-template *ngIf="noDataComponent else noDataTemplate" [ngComponentOutlet]="noDataComponent"></ng-template>
        </div>

        <table [hidden]="!vsComponent.viewPortItems?.length"
               (mousedown)="tableMousedown($event)"
               (dblclick)="dblClick($event, container, headerContainer)">

            <colgroup>
                <col *ngFor="let column of _columns"
                     [style.width.px]="column.colWidth | acFunc : acTableService.getLimitedWidth : (column.minWidth || minCellWidth)">
            </colgroup>
            <!-- #container is saved word for virtual scroller -->
            <tbody ac-table-body
                   [columns]="_columns"
                   [rows]="vsComponent.viewPortItems"
                   [showBufferLoader]="bufferingInfiniteScroll"
                   [templates]="{infiniteScrollBufferLoader: infiniteScrollBufferLoader}"
                   #container
            ></tbody>
        </table>

    </virtual-scroller>

    <div class="flexItem horizontal-scroller"
         (scroll)="updateHorizontalScroll($event, [headerContainer, footerContainer])"
         #horizontalScroller>
        <div class="horizontal-scroller-item"
             [style.width.px]="headerTable | acFunc : calcHorizontalScrollWidth: viewportScrollBarWidth : headerTableWidthChanges"
        ></div>
    </div>

    <div class="footer-table flexItem" #footerContainer [hidden]="!footer">
        <table *ngIf="footer" [style.padding-right.px]="viewportScrollBarWidth | acFunc : acTableService.getLimitedWidth">

            <tfoot ac-table-footer [columns]="_columns"></tfoot>
        </table>
    </div>

    <ac-pagination class="flexItem"
                   *ngIf="paginator && !infiniteScroll"
                   [class.rtl]="rtl"
                   [pageIndex]="pageIndex"
                   [pageSize]="pageSize"
                   [itemsDisplayTemplate]="pageItemsDisplayTemplate"
                   [itemsDisplayType]="itemsDisplayType"
                   [pageSelector]="pageSelector"
                   [totalElements]="totalElements"
                   [pageSizeOptions]="pagingSizeOption"
                   (pageIndexChange)="onPageChange($event)"
                   (pageSizeChange)="onPageChange($event)">


        <ng-template #postfixTemplate>
            <ac-button class="small"
                       buttonType="icon-button"
                       (acClick)="openTableSettings()">
                <ac-svg name="settings" [fillColor]="statusColors.primary"></ac-svg>
            </ac-button>
        </ng-template>
    </ac-pagination>

    <ac-progress [hidden]="!loading && (isHeaderVisible || isBodyVisible)"
                 class="overlay-on-load background"
                 [center]="true"></ac-progress>
</div>

<ng-template #noDataTemplate>
    <ng-template [ngTemplateOutlet]="noDataPlaceholderContentTemplate || noDataDefaultTemplate"></ng-template>
</ng-template>
<ng-template #noDataDefaultTemplate>No data</ng-template>
